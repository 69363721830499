<template>
  <div class="container">
    <PublicHeader :totalName="$t('authentication').title" />
    <van-field
      v-model="form.RealName"
      :label="$t('authentication').RealName"
      :placeholder="$t('authentication').placeholder"
    />
    <!-- <van-field
      v-model="form.PhoneNumber"
      :label="$t('authentication').Mobile"
      :placeholder="$t('authentication').placeholder1"
      @input="form.PhoneNumber = form.PhoneNumber.replace(/[^\d]/g, '')"
    /> -->
    <van-field
      v-model="form.IdCard"
      :label="$t('authentication').IDNumber"
      :placeholder="$t('authentication').placeholder2"
    />
    <van-field
      v-model="form.IdCardAddress"
      :label="$t('authentication').CertificateAddress"
      :placeholder="$t('authentication').placeholder3"
    />
    <van-field
      v-model="form.Residence"
      :label="$t('authentication').CurrentAddress"
      :placeholder="$t('authentication').placeholder4"
    />
    <div v-if="!IsReal">
      <p>{{ $t('authentication').warnInfo }}</p>
      <div class="bigBtn" @click="handleSubmit">
        {{ $t('authentication').Confirm }}
      </div>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      IsReal: true,
      form: {
        RealName: '',
        PhoneNumber: '',
        IdCard: '',
        IdCardAddress: '',
        Residence: '',
      },
    }
  },
  methods: {
    async getUserInfo() {
      let res = await userApi.userInfo()
      for (let i in this.form) {
        this.form[i] = res[i]
      }
      if (!res.IsReal) {
        this.IsReal = false
      }
    },
    async handleSubmit() {
      let form = { ...this.form }
      for (let i in form) {
        if (form[i] === '') {
          this.$toast({
            message: this.$t('authentication').message,
            position: 'bottom',
          })
          return
        }
      }
      await userApi.realName(form)
      this.$toast(this.$t('authentication').success)
      this.$router.go(-1)
    },
  },
  mounted() {
    this.getUserInfo()
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/mixin.styl'
.container
  width 100%
  background-color #1f1f1f
  font-size 14px
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  p
    color #e60d0d
    font-size 13px
    margin 15px 0 10px 15px

  .van-field
    background none
    >>> .van-field__label
      color #ebe5cd
      margin-right 0
    >>> .van-field__control
      color #fff
    >>> input::-webkit-input-placeholder
      color #969799
  .bigBtn
    width 92%
    height 40px
    flex-center()
    color #222222
    font-size 15px
    background linear-gradient(315deg, #e8d49e, #d6b372) !important
    margin 30px auto
    border-radius 5px
</style>
